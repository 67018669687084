var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.step
    ? _c(
        "div",
        [
          _vm.showSubHeader
            ? _c("SetupWizardSubHeader", { attrs: { step: _vm.step } })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
            },
            [_c(_vm.step.componentName, { tag: "component" })],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div v-if="step">
    <SetupWizardSubHeader v-if="showSubHeader" :step="step" />

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <component :is="step.componentName" />
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import Types from '../store/Types';
import SetupWizardSubHeader from '../rootComponents/SetupWizardSubHeader.vue';

const SchoolTermSettings = () => import( '../rootComponents/SchoolTermSettings.vue');
const SetupGoogleDomain = () => import( '../rootComponents/GoogleAccount/SetupGoogleDomain.vue');
const GoogleAccount = () => import( '../rootComponents/GoogleAccount/GoogleAccount.vue');
const GoogleCourseManager = () => import( '../rootComponents/GoogleCourseManager/GoogleCourseManager.vue');
const UploadRosters = () => import( '../rootComponents/UploadRosters.vue');
const DataEncryptionSetup = () => import( '../rootComponents/DataEncryptionSetup.vue');
const BadgeSetupWizard = () => import( '../rootComponents/BadgeSetupWizard.vue');
const GoogleUserLink = () => import( '../rootComponents/GoogleUserLink/GoogleUserLink.vue');
const CommunicationSetup = () => import( '../rootComponents/CommunicationSetup.vue');
const GradeTemplateEditCreate = () => import( '../rootComponents/GradeTemplateEditCreate.vue');


export default Vue.extend({
    name: 'SetupWizard',
    components: {
        SetupWizardSubHeader,
        SchoolTermSettings,
        SetupGoogleDomain,
        GoogleAccount,
        GoogleCourseManager,
        UploadRosters,
        DataEncryptionSetup,
        BadgeSetupWizard,
        GoogleUserLink,
        CommunicationSetup,
        GradeTemplateEditCreate,
    },
    data() {
        return {
        };
    },
    computed: {
        totalSteps() {
            return this.steps.length;
        },
        steps() {
            return this.$store.state.wizard.steps;
        },
        step() {
            const { routeName } = this.$route.params;
            const [first] = this.steps;
            return this.steps.find(function(s) {
                return s.routeName === routeName;
            }) || first;
        },
        showSubHeader() {
            const { step } = this;
            if (!step) return false;
            if (!step.hasOwnProperty('showSubHeader')) return false;
            return this.step.showSubHeader;
        },
    },
    watch: {
        $route() {
            this.configure();
        },
    },
    mounted() {
        setTimeout(() => {
            this.configure();
        }, 200);
    },
    methods: {
        configure() {
            const v = this;
            this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);

            if (this.showSubHeader) {
                document.body.classList.add('kt-subheader--enabled', 'kt-subheader--fixed', 'kt-subheader--solid');
            } else {
                document.body.classList.remove('kt-subheader--enabled', 'kt-subheader--fixed', 'kt-subheader--solid');
            }
        },
    },
});
</script>

<style scoped>
</style>
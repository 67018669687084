<template>
<div
    :key="`key_${key}`"
    class="kt-subheader kt-grid__item"
>
    <div v-if="step" class="kt-container kt-container--fluid ">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                {{ step.title }}
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v ml-3" />
            <slot name="left-buttons" />
        </div>

        <div class="kt-subheader__toolbar">
            <slot name="buttons" />
            <!-- <div class="kt-subheader__wrapper">
                <button
                    :class="'btn-clean-primary'"
                    class="btn kt-subheader__btn-primary"
                    @click="complete(step)"
                >
                    Mark as Complete
                </button>
            </div> -->
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Types from '../store/Types';

export default {
    name: 'SetupWizardSubHeader',
    data() {
        return {
            key: 0,
            steps: [],
        };
    },
    computed: {
        step() {
            const { routeName } = this.$route.params;
            return this.$store.state.wizard.steps.find((step) => step.routeName === routeName);
        },
    },
    mounted() {
    },
    methods: {
        complete(step) {
            this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, { routeName: step.routeName, value: 'true' });
        },
        clear(step) {
            this.$store.commit(Types.mutations.SKIP_SETUP_FOR_ROUTE, { routeName: step.routeName, value: '' });
        },
    },
};

</script>

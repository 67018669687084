var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.key}`, staticClass: "kt-subheader kt-grid__item" },
    [
      _vm.step
        ? _c("div", { staticClass: "kt-container kt-container--fluid" }, [
            _c(
              "div",
              { staticClass: "kt-subheader__main" },
              [
                _c("h3", { staticClass: "kt-subheader__title" }, [
                  _vm._v(" " + _vm._s(_vm.step.title) + " "),
                ]),
                _c("span", {
                  staticClass:
                    "kt-subheader__separator kt-subheader__separator--v ml-3",
                }),
                _vm._t("left-buttons"),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "kt-subheader__toolbar" },
              [_vm._t("buttons")],
              2
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }